import {
  Add,
  ArrowBackIos,
  Cancel,
  CheckBox,
  Close,
  Dvr,
  Publish,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Styles/SpaceAdd.css";
import placeholder from "../Images/house.png";
import { db, storage } from "../../firebase";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";

/* to do list */
/* pull the default values from the database */
/* handle updatading instead of adding to the database */

function SpaceEdit() {
  const { parent_tradesman, job_id, space_id } = useParams();
  const { currentUser } = useAuth();

  React.useEffect(() => {
    db.collection("Employee_Jobs")
      .doc(parent_tradesman)
      .collection(currentUser.uid)
      .doc(job_id)
      .get()
      .then((e) => {
        db.collection("new_audits")
          .doc(e.data().related_job)
          .collection("spaces")
          .doc(space_id)
          .get()
          .then((r) => {
            const data = r.data();
            setState((state) => ({
              ...state,
              display_name: data.display_name,
              images: data.images,
              description: data.description,
              length: data.length,
              width: data.width,
              height: data.height,
              measurement_type: data.measurement_type,
              space_data: data,
              mergedImages: data.images,
            }));
          });
      });
  }, []);

  const [state, setState] = React.useState({
    display_name: "",
    images: [],
    description: "",
    length: "",
    width: "",
    height: "",
    measurement_type: "Metres",
    files: [],
    job_data: {},
    uploaded_images: [],
    errorOpen: false,
    successOpen: false,
    successMessage: "",
    errorMessage: "",
    actual_files: [],
    loading: false,
    space_data: {},
    mergedImages: [],
    assets: [],
  });

  function handleStringChange(e, field) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  const handleChange = async (e) => {
    const files = e.target.files;
    for (let i = 0; i < e.target.files.length; i++) {
      const file = files[i];
      await setState((state) => ({
        ...state,
        actual_files: [...state.actual_files, file],
      }));
    }    
  };

  function imageComponent(url, index) {
    return (
      <Badge
        badgeContent={
          <IconButton size="small" onClick={(e) => handleImageRemove(index)}>
            <Close style={{ color: "rgb(18, 100, 100)" }} />
          </IconButton>
        }
        overlap="rectangular"
        style={{ width: "100%", marginLeft: "10px", marginTop: "10px" }}
      >
        <img src={url} alt="img upload" className="AuditHome_img" />
      </Badge>
    );
  }

  const navigate = useNavigate();

  function handleDataBaseAddSpace() {
    db.collection("new_audits")
      .doc(state.job_data.related_job)
      .collection("spaces")
      .doc(space_id)
      .update({
        display_name: state.display_name,
        description: state.description,
        length: state.length,
        width: state.width,
        height: state.height,
        measurement_type: state.measurement_type,
        images: state.images,
        employee_id: currentUser.uid,
        related_tradesman: currentUser.uid,
      })
      .then((r) => {
        setState((state) => ({
          ...state,
          successOpen: true,
          successMessage: "Successfully Updated Space",
        }));
      })
      .catch((r) => {
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to Update Space",
        }));
      });
  }



  React.useEffect(() => {
    db.collection("new_audits")
                .doc(state.job_data.related_job)
                .collection("spaces")
                .doc(space_id)
                .update({
                  images: state.images,
                });
  }, [state.images])
  

  async function handleImageUpload() {
    const files = [...state.actual_files];
    await files.forEach(async (file) => {
      const fileName = `${file.name}_${Math.random()}`
      const uploadTask = storage
        .ref(`${currentUser.uid}/space_images/${fileName}`)
        .put(file);
      await uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "failed to upload image",
          }));
        },
        async () => {
          // complete function ....
          await storage
            .ref(`${currentUser.uid}/space_images`)
            .child(fileName) // Upload the file and metadata
            .getDownloadURL() // get download url
            .then(async (url) => {
              setState((state) => ({
                ...state,
                images: [...state.images, { url: url, title: fileName}],
                loading: false,
                actual_files: [],
              }));
              db.collection("new_audits")
                .doc(state.job_data.related_job)
                .collection("spaces")
                .doc(space_id)
                .update({
                  images: [...state.images, { url: url, title:  fileName }],
                });
            });
        }
      );
    });
  }


  

  React.useEffect(() => {
    db.collection("new_audits")
      .doc(state.job_data.related_job)
      .collection("assets")
      .where("realted_space", "==", space_id)
      .get()
      .then((snapshot) => {
        setState((state) => ({
          ...state,
          assets: snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        }));
        console.log(snapshot);
      });
    console.log(state.job_data.related_job, space_id);
  }, [state.job_data]);

  async function handleSpaceUpdate() {
    await setState((state) => ({ ...state, loading: true }));
    await handleDataBaseAddSpace();
    setState((state) => ({ ...state, loading: false }));
  }

  React.useEffect(() => {
    db.collection("Employee_Jobs")
      .doc(parent_tradesman)
      .collection(currentUser.uid)
      .doc(job_id)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          job_data: r.data(),
        }));
        console.log(r.data());
      });
  }, []);

  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
    }));
  };

  function handleSpaceDelete() {
    if (window.confirm("Are you sure you want to delete this space?")) {
      db.collection("new_audits")
        .doc(state.job_data.related_job)
        .collection("spaces")
        .doc(space_id)
        .delete()
        .then((r) => {
          setState((state) => ({
            ...state,
            successOpen: true,
            successMessage: "Successfully Deleted Space",
          }));

          const timeout = setTimeout(() => {
            navigate(`/audit/${parent_tradesman}/edit/${job_id}`);
          }, 1000);
        })
        .catch((r) => {
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to Delete Space",
          }));
        });
    } else {
      return;
    }
  }

  async function handleImageRemove(index) {
    if (window.confirm("are you sure you want to delete this image?")) {
      const newArr = [...state.images];
      await newArr.splice(index, 1);
      await setState((state) => ({
        ...state,
        images: newArr,
      }));
      db.collection("new_audits")
        .doc(state.job_data.related_job)
        .collection("spaces")
        .doc(space_id)
        .update({
          images: newArr,
        });
    } else {
      return;
    }
  }

  return (
    <>
      <Header />
      <div className="header_page_title">
        <div>Edit Space</div>
      </div>
      <div className="AuditSpaceAdd_content">
        <Card className="AuditSpaceAdd_card">
          <div>
            <IconButton
              size="small"
              onClick={(e) => {
                navigate(`/audit/${parent_tradesman}/edit/${job_id}`);
              }}
            >
              <ArrowBackIos style={{ color: "rgb(18 100, 100)" }} />
            </IconButton>
          </div>

          <div className="AuditHome_img_parent">
            {state.images[0] ? (
              <>
                <div className="SpaceAdd_images">
                  {state.images &&
                    state.images.map((file, index) => (
                      <div style={{ marginLeft: "10px" }}>
                        {imageComponent(file.url, index)}
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <img
                  src={placeholder}
                  alt="img upload"
                  className="AuditHome_img"
                />
              </>
            )}
          </div>
          <div className="dialog_box_select_file">
            <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              capture="environment"
              className={"dialog_box_select_file"}
              onChange={handleChange}
            />

            <IconButton size="small">
              <Publish
                style={{ color: "rgb(18, 100, 100)" }}
                onClick={(e) => {
                  handleImageUpload();
                }}
              />
            </IconButton>
          </div>

          <div className="SpaceAdd_Textfields">
            <TextField
              fullWidth
              style={{ marginTop: "10px" }}
              size="small"
              label="Space Display Name"
              placeholder="Master Bedroom...."
              onChange={(e) => {
                handleStringChange(e, "display_name");
              }}
              value={state.display_name}
              onBlur={(e) => {
                handleSpaceUpdate();
              }}
            />
            <TextField
              fullWidth
              style={{ marginTop: "10px" }}
              size="small"
              label="Space Description"
              placeholder="First bedroom on left as you walk through front door..."
              multiline
              rows={5}
              onChange={(e) => {
                handleStringChange(e, "description");
              }}
              value={state.description}
              onBlur={(e) => {
                handleSpaceUpdate();
              }}
            />

            <div className="SpaceAdd_measurment_section">
              <div className="SpaceAdd_measurments">
                <TextField
                  fullWidth
                  style={{ marginTop: "10px" }}
                  size="small"
                  label="Length"
                  onChange={(e) => {
                    handleStringChange(e, "length");
                  }}
                  value={state.length}
                  onBlur={(e) => {
                    handleSpaceUpdate();
                  }}
                />
                <TextField
                  fullWidth
                  style={{ marginTop: "10px" }}
                  size="small"
                  label="Width"
                  onChange={(e) => {
                    handleStringChange(e, "width");
                  }}
                  value={state.width}
                  onBlur={(e) => {
                    handleSpaceUpdate();
                  }}
                />
                <TextField
                  fullWidth
                  style={{ marginTop: "10px" }}
                  size="small"
                  label="Height"
                  onChange={(e) => {
                    handleStringChange(e, "height");
                  }}
                  value={state.height}
                  onBlur={(e) => {
                    handleSpaceUpdate();
                  }}
                />
              </div>
              <div className="SpaceAdd_measurments2">
                <FormControl variant="outlined" style={{ marginTop: "10px" }}>
                  <InputLabel>measurement</InputLabel>
                  <Select
                    fullWidth
                    native
                    label="measurement"
                    size="small"
                    onChange={(e) => {
                      handleStringChange(e, "measurement_type");
                    }}
                    value={state.measurement_type}
                    onBlur={(e) => {
                      handleSpaceUpdate();
                    }}
                  >
                    <option value="Metres">Metres </option>
                    <option value="Feet">Feet </option>
                    <option value="Centimetres">Centimetres </option>
                    <option value="Millimetres">Millimetres </option>
                    <option value="Inches">Inches </option>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="AuditHome_sub_heading" style={{ marginTop: "20px" }}>
            <div>Space Assets</div>
          </div>
          <div>
            {state.assets &&
              state.assets.map((asset) => (
                <>
                  <ListItem
                    key={asset.id}
                    button
                    style={{ padding: "5px" }}
                    onClick={(e) => {
                      navigate(
                        `/audit/${parent_tradesman}/edit/${job_id}/edit_space/${space_id}/edit_asset/${asset.id}`
                      );
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={
                          asset.images && asset.images[0] && asset.images[0].url
                        }
                        alt={asset.display_name}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={asset.display_name}
                      secondary={asset.description}
                    />
                  </ListItem>
                </>
              ))}
          </div>
          <div className="AuditHome_asset_add">
            <Fab
              style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
              size="small"
              onClick={(e) => {
                navigate(
                  `/audit/${parent_tradesman}/edit/${job_id}/edit_space/${space_id}/add_asset/`
                );
              }}
            >
              <Add />
            </Fab>
          </div>
          <div>
            <Button
              style={{ color: "rgb(18, 100, 100)", margin: "10px" }}
              onClick={(e) => {
                handleSpaceDelete();
              }}
            >
              Delete Space
            </Button>
          </div>
        </Card>
      </div>

      {state.loading && (
        <div className="SpaceAdd_loading_background">
          <div className="SpaceAdd_loading_sub">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
        </div>
      )}

      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SpaceEdit;
