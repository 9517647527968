export function handleAddressString(addressObj) {
  const {
    address_city,
    address_country,
    address_line_1,
    address_line_2,
    address_postcode,
    address_state,
  } = addressObj;

  return `${address_line_1 && address_line_1}, 
      ${address_line_2 || address_line_2 === "" ? "" : `${address_line_2},`}
      ${address_city && address_city}, ${address_country && address_country}`;
}
