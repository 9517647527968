import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Screens/Login";
import Dashboard from "./Components/Screens/Dashboard";
import { AuthProvider } from "./AuthContext";
import AuditHome from "./Components/Screens/AuditHome";
import AuditSpaceAdd from "./Components/Screens/AuditSpaceAdd";
import SpaceAdd from "./Components/Screens/SpaceAdd";
import SpaceEdit from "./Components/Screens/SpaceEdit";
import AssetAdd from "./Components/Screens/AssetAdd";
import AssetEdit from "./Components/Screens/AssetEdit";
import WholePropertyAssets from "./Components/Screens/WholePropertyAssets";
import AddAsset from "./Components/Screens/Add Asset/AddAsset";


function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route index element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/add_asset/:job_id" element={<AddAsset />} />
            <Route path="/add_asset/:job_id/:related_job" element={<AddAsset />} />
            <Route path="/audit/:parent_tradesman/edit/:job_id" element={<AuditSpaceAdd />} />
            <Route path="/audit/:parent_tradesman/edit/:job_id/add_space" element={<SpaceAdd />} />
            <Route path="/audit/:parent_tradesman/edit/:job_id/edit_space/:space_id" element={<SpaceEdit />} />
            <Route path="/audit/:parent_tradesman/edit/:job_id/edit_space/:space_id/add_asset/" element={<AssetAdd />} />
            <Route path="/audit/:parent_tradesman/edit/:job_id/edit_space/:space_id/edit_asset/:asset_id" element={<AssetEdit />} />
            <Route path="/audit/:job_id" element={<AuditHome />} />
            <Route path="/audit/:job_id/:related_job" element={<AuditHome />} />


            <Route path="/audit/:parent_tradesman/edit/:job_id/whole_property/:group" element={<WholePropertyAssets />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}
export default App;
