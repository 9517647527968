import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../AuthContext";
import { db } from "../../../../firebase";
import "../Styles/Slides.css";

function ProductDetails() {
  // hooks
  const { currentUser } = useAuth();
  const { job_id } = useParams();

  // state variables
  const [state, setState] = React.useState({
    product_type: "",
    serial_number: "",
    manufactuer: "",
    product_code: "",
  });

  // useEffect
  React.useEffect(() => {
    db.collection("new-assets")
      .doc(currentUser.uid)
      .collection("assets")
      .doc(job_id)
      .get()
      .then((r) => {
        if (r.exists) {
          setState((state) => ({
            ...state,
            product_type: r.data().product_type ? r.data().product_type : "",
            serial_number: r.data().serial_number ? r.data().serial_number : "",
            manufactuer: r.data().manufactuer ? r.data().manufactuer : "",
            product_code: r.data().product_code ? r.data().product_code : "",
          }));
        } else {
          return;
        }
      });
  }, [currentUser, job_id]);


  // handle autosave
function handleBlur () { 
  db.collection("new-assets")
  .doc(currentUser.uid)
  .collection("assets")
  .doc(job_id)
  .set({
    product_type: state.product_type,
    serial_number: state.serial_number,
    manufactuer: state.manufactuer,
    product_code: state.product_code,
  }, {merge: true}).then((r) => {
    setState((state) => ({
      ...state, 
      errorOpen: false, 
      errorMessage: '', 
    }))
  }).catch((er) => {
    console.log(er)
    setState((state) => ({
      ...state, 
      errorOpen: true, 
      errorMessage: 'Failed to save progress...', 
    }))

  })
}


  return (
    <div className="slide">
      <div className="title">Product Details</div>
      <div className="fields">
        <div className="fullwidth">
          <TextField value={state.product_type} onBlur={handleBlur} onChange={((e) => {setState((state) => ({...state, product_type: e.target.value}))})}
            fullWidth
            label="Product Type"
            placeholder="Hot Water Service..."
          />
        </div>
        <div className="fullwidth">
          <TextField value={state.serial_number} onBlur={handleBlur} onChange={((e) => {setState((state) => ({...state, serial_number: e.target.value}))})}
            fullWidth
            label="Serial Number"
            placeholder="1234567-1294"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
          />
        </div>
        <div className="fullwidth">
          <TextField value={state.manufactuer} onBlur={handleBlur} onChange={((e) => {setState((state) => ({...state, manufactuer: e.target.value}))})} fullWidth label="Manufactuer" placeholder="Rheem..." />
        </div>
        <div className="fullwidth">
          <TextField value={state.product_code} onBlur={handleBlur} onChange={((e) => {setState((state) => ({...state, product_code: e.target.value}))})} fullWidth label="Product Code" placeholder="XYZ-123" />
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
