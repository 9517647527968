import { Add, Cancel, CheckBox, Close, Dvr, Publish, Save } from "@mui/icons-material";
import {
  Alert,
  Badge,
  Button,
  Card,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Styles/AssetAdd.css";
import placeholder from "../Images/house.png";
import { db, storage } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

function AssetAdd() {
  const { parent_tradesman, job_id, space_id } = useParams();
  const { currentUser } = useAuth();

  const [state, setState] = React.useState({
    display_name: "",
    images: [],
    description: "",
    install_date: new Date(),
    installer_name: "",
    warranty_amount: "",
    warranty_measure: "Years",
    serial_number: "",
    product_code: "",
    asset_type: "",
    files: [],
    job_data: {},
    uploaded_images: [],
    errorOpen: false,
    successOpen: false,
    successMessage: "",
    errorMessage: "",
    actual_files: [],
    loading: false,
    manufactuer: "",
  });

  function handleStringChange(e, field) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  const handleChange = async (e) => {
    const files = e.target.files;

    for (let i = 0; i < e.target.files.length; i++) {
      const file = files[i];
      await setState((state) => ({
        ...state,
        actual_files: [...state.actual_files, file],
      }));
    }
  };

  function handleImageRemove  (index) {
    if (window.confirm('are you sure you want to delete this image?')) {
const newArr = [...state.uploaded_images]
    newArr.splice(index, 1)
    setState((state) => ({
      ...state,
      uploaded_images: newArr
    }))
    } else {
      return;
    }
    
  }

  function imageComponent(url, index) {
    return (
      <Badge
        badgeContent={
          <IconButton size="small" onClick={((e) => handleImageRemove(index))}>
            <Close style={{color:'rgb(18, 100, 100)'}} />
          </IconButton>
        }
        overlap='rectangular'
        style={{ width: "100%", marginLeft: "10px", marginTop: "10px" }}
      >
        <img src={url} alt="img upload" className="AuditHome_img" />
      </Badge>
    );
  }

  const navigate = useNavigate();

  function handleDataBaseAddSpace() {
    db.collection("new_audits").doc(state.job_data.related_job).set({
      spaces: true,
      related_tradesman: currentUser.uid,
    });
    db.collection("new_audits")
      .doc(state.job_data.related_job)
      .collection("assets")
      .add({
        display_name: state.display_name,
        description: state.description,
        install_date: state.install_date,
        installer_name: state.installer_name,
        warranty_amount: state.warranty_amount,
        warranty_measure: state.warranty_measure,
        serial_number: state.serial_number,
        product_code: state.product_code,
        asset_type: state.asset_type,
        images: state.uploaded_images,
        employee_id: currentUser.uid,
        related_tradesman: currentUser.uid,
        realted_space: space_id,
        manufactuer: state.manufactuer,
      })
      .then((r) => {
        setState((state) => ({
          ...state,
          successOpen: true,
          successMessage: "Successfully Added Space",
        }));
      })
      .catch((r) => {
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to Add Space",
          loading: false,
        }));
      });
  }

  async function handleImageUpload() {
    const files = [...state.actual_files];
    await files.forEach(async (file) => {
      const fileName = `${file.name}_${Math.random()}`
      const uploadTask = storage
        .ref(`${currentUser.uid}/space_images/${fileName}`)
        .put(file);
      await uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "failed to upload image",
          }));
        },
        async () => {
          // complete function ....
          await storage
            .ref(`${currentUser.uid}/space_images`)
            .child(fileName) // Upload the file and metadata
            .getDownloadURL() // get download url
            .then(async (url) => {
              setState((state) => ({
                ...state,
                uploaded_images: [
                  ...state.uploaded_images,
                  { url: url, title: fileName },
                ],
                loading: false,
                actual_files: [],
              }));
            });
        }
      );
    });
  }

  async function handleSpaceAdd() {
    await setState((state) => ({ ...state, loading: true }));
    await handleDataBaseAddSpace();

    if (
      space_id === "heating_and_cooling_assets" ||
      space_id === "energy_assets" ||
      space_id === "utilities_and_other_assets"
    ) {
      const timeout = setTimeout(() => {
        navigate(
          `/audit/${parent_tradesman}/edit/${job_id}/whole_property/${space_id}`
        );
      }, 1000);
    } else {
      const timeout = setTimeout(() => {
        navigate(
          `/audit/${parent_tradesman}/edit/${job_id}/edit_space/${space_id}`
        );
      }, 1000);
      setState((state) => ({ ...state, loading: false }));
    }
  }

  React.useEffect(() => {
    db.collection("Employee_Jobs")
      .doc(parent_tradesman)
      .collection(currentUser.uid)
      .doc(job_id)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          job_data: r.data(),
        }));
        console.log(r.data());
      });
  }, []);

  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
    }));
  };

  function handleDateChange(e) {
    setState((state) => ({
      ...state,
      install_date: e,
    }));
  }
  return (
    <>
      <Header />
      <div className="header_page_title">
        <div>Add Asset</div>
      </div>
      <div className="AuditSpaceAdd_content">
        <Card className="AuditSpaceAdd_card" style={{ marginBottom: "80px" }}>
          <div className="AuditHome_sub_heading">
            <div>New Asset</div>
          </div>

          <div className="AuditHome_img_parent">
            {state.actual_files ? (
              <>
                <div className="SpaceAdd_images">
                  {state.uploaded_images &&
                    state.uploaded_images.map((file, index) => (
                      <div style={{ marginLeft: "10px" }}>
                        {file.url && imageComponent(file.url, index)}
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <img
                  src={placeholder}
                  alt="img upload"
                  className="AuditHome_img"
                />
              </>
            )}
          </div>
          <div className="dialog_box_select_file">
            <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              capture="environment"
              multiple
              className={"dialog_box_select_file"}
              onChange={handleChange}
            />

            <IconButton size="small">
              <Publish
                style={{ color: "rgb(18, 100, 100)" }}
                onClick={(e) => {
                  handleImageUpload();
                }}
              />
            </IconButton>
          </div>

          <div className="SpaceAdd_Textfields">
            <TextField
              fullWidth
              style={{ marginTop: "10px" }}
              size="small"
              margin="dense"
              label="Asset Display Name"
              placeholder="Gas/Electric Hot Water Service..."
              onChange={(e) => {
                handleStringChange(e, "display_name");
              }}
              value={state.display_name}
            />
            <TextField
              fullWidth
              style={{ marginTop: "10px" }}
              size="small"
              label="Asset Description"
              placeholder="Main hotwater service for the property..."
              multiline
              rows={3}
              onChange={(e) => {
                handleStringChange(e, "description");
              }}
              value={state.description}
            />

            <div className="AssetAdd_half_fields">
              <div className="AssetAdd_half_field_30">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    style={{ marginTop: "10px" }}
                    id="date-picker-inline"
                    label="Install Date"
                    formatDate={(date) =>
                      DateFnsUtils(date).format("YYYY-MM-DD")
                    }
                    fullWidth={true}
                    value={state.install_date}
                    inputVariant="outlined"
                    // size="small"
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="AssetAdd_half_field_70">
                <TextField
                  fullWidth
                  style={{ marginTop: "10px" }}
                  size="small"
                  label="Installer Name"
                  onChange={(e) => {
                    handleStringChange(e, "installer_name");
                  }}
                  value={state.installer_name}
                />
              </div>
            </div>

            <div className="AssetAdd_half_fields">
              <div className="AssetAdd_half_field_30">
                <TextField
                  fullWidth
                  style={{ marginTop: "10px" }}
                  size="small"
                  label="Warranty Amount"
                  onChange={(e) => {
                    handleStringChange(e, "warranty_amount");
                  }}
                  value={state.warranty_amount}
                />
              </div>
              <div className="AssetAdd_half_field_70">
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel>Warranty Measure</InputLabel>
                  <Select
                    fullWidth
                    native
                    label="Warranty Measure"
                    size="small"
                    onChange={(e) => {
                      handleStringChange(e, "warranty_measure");
                    }}
                    value={state.warranty_measure}
                  >
                    <option value="Days">Days </option>
                    <option value="Weeks">Weeks </option>
                    <option value="Months">Months </option>
                    <option value="Years">Years </option>
                    <option value="Generations">Generations </option>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="AssetAdd_half_fields">
              <TextField
                fullWidth
                style={{ marginTop: "10px" }}
                size="small"
                label="Serial Number"
                onChange={(e) => {
                  handleStringChange(e, "serial_number");
                }}
                value={state.serial_number}
              />
            </div>
            <div className="AssetAdd_half_fields">
              <TextField
                fullWidth
                style={{ marginTop: "10px" }}
                size="small"
                label="Manufactuer"
                onChange={(e) => {
                  handleStringChange(e, "manufactuer");
                }}
                value={state.manufactuer}
              />
            </div>
            <div className="AssetAdd_half_fields">
              <TextField
                fullWidth
                style={{ marginTop: "10px" }}
                size="small"
                label="Product Code"
                onChange={(e) => {
                  handleStringChange(e, "product_code");
                }}
                value={state.product_code}
              />
            </div>
            <div className="AssetAdd_half_fields">
              <TextField
                fullWidth
                style={{ marginTop: "10px" }}
                size="small"
                label="Asset Type"
                onChange={(e) => {
                  handleStringChange(e, "asset_type");
                }}
                value={state.asset_type}
              />
            </div>
          </div>
        </Card>
      </div>

      {state.loading && (
        <div className="SpaceAdd_loading_background">
          <div className="SpaceAdd_loading_sub">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
        </div>
      )}

      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="AssetAdd_fab">
        <Fab
          style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
          onClick={(e) => {
            handleSpaceAdd();
          }}
        >
          <Save />
        </Fab>
      </div>
    </>
  );
}

export default AssetAdd;
