import {
  Add,
  Cancel,
  CheckBox,
  Close,
  Dvr,
  Publish,
  Save,
} from "@mui/icons-material";
import {
  Alert,
  Badge,
  Button,
  Card,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Styles/SpaceAdd.css";
import placeholder from "../Images/house.png";
import { db, storage } from "../../firebase";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import './Styles/AssetAdd.css'

function SpaceAdd() {
  const { parent_tradesman, job_id } = useParams();
  const { currentUser } = useAuth();

  const [state, setState] = React.useState({
    display_name: "",
    images: [],
    description: "",
    length: "",
    width: "",
    height: "",
    measurement_type: "Metres",
    files: [],
    job_data: {},
    uploaded_images: [],
    errorOpen: false,
    successOpen: false,
    successMessage: "",
    errorMessage: "",
    actual_files: [],
    loading: false,
    doc_id: "",
  });

  function handleStringChange(e, field) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  const handleChange = async (e) => {
    const files = e.target.files;
    const holdingArr = [...state.files];
    const holdingArr2 = [...state.actual_files];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = files[i];
      const newUrl = URL.createObjectURL(file);
      await holdingArr.push({ ...file, url: newUrl });
      await holdingArr2.push(file);
    }
    await setState((state) => ({
      ...state,
      files: holdingArr,
      actual_files: holdingArr2,
    }));
    console.log(state.files);
  };

  function imageComponent(url, index) {
    return (
      <Badge
        badgeContent={
          <IconButton size="small" onClick={(e) => handleImageRemove(index)}>
            <Close style={{ color: "rgb(18, 100, 100)" }} />
          </IconButton>
        }
        overlap="rectangular"
        style={{ width: "100%", marginLeft: "10px", marginTop: "10px" }}
      >
        <img src={url} alt="img upload" className="AuditHome_img" />
      </Badge>
    );
  }

  const navigate = useNavigate();

  function handleDataBaseAddSpace() {
    db.collection("new_audits").doc(state.job_data.related_job).set({
      spaces: true,
      related_tradesman: currentUser.uid,
    });
    db.collection("new_audits")
      .doc(state.job_data.related_job)
      .collection("spaces")
      .add({
        display_name: state.display_name,
        description: state.description,
        length: state.length,
        width: state.width,
        height: state.height,
        measurement_type: state.measurement_type,
        images: state.uploaded_images,
        employee_id: currentUser.uid,
        related_tradesman: currentUser.uid,
      })
      .then((r) => {
        setState((state) => ({
          ...state,
          successOpen: true,
          successMessage: "Successfully Added Space",
          doc_id: r.id,
        }));
      })
      .catch((r) => {
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to Add Space",
        }));
      });
  }
  function handleDataBaseAddSpaceForSpaceAdd() {
    db.collection("new_audits").doc(state.job_data.related_job).set({
      spaces: true,
      related_tradesman: currentUser.uid,
    });
    db.collection("new_audits")
      .doc(state.job_data.related_job)
      .collection("spaces")
      .add({
        display_name: state.display_name,
        description: state.description,
        length: state.length,
        width: state.width,
        height: state.height,
        measurement_type: state.measurement_type,
        images: state.uploaded_images,
        employee_id: currentUser.uid,
        related_tradesman: currentUser.uid,
      })
      .then((r) => {
        navigate(
          `/audit/${parent_tradesman}/edit/${job_id}/edit_space/${r.id}/add_asset/`
        );

        setState((state) => ({
          ...state,
          successOpen: true,
          successMessage: "Successfully Added Space",
          doc_id: r.id,
        }));
      })
      .catch((r) => {
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to Add Space",
        }));
      });
  }

  async function handleImageUpload() {
    const files = [...state.actual_files];
    await files.forEach(async (file) => {
      const fileName = `${file.name}_${Math.random()}`

      const uploadTask = storage
        .ref(`${currentUser.uid}/space_images/${fileName}`)
        .put(file);
      await uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setState((state) => ({
            ...state,
            loading: true,
          }));
        },
        (error) => {
          // error function ....
          console.log(error);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "failed to upload image",
          }));
        },
        async () => {
          // complete function ....
          await storage
            .ref(`${currentUser.uid}/space_images`)
            .child(fileName) // Upload the file and metadata
            .getDownloadURL() // get download url
            .then(async (url) => {
              setState((state) => ({
                ...state,
                uploaded_images: [
                  ...state.uploaded_images,
                  { url: url, title: fileName },
                ],
                loading: false,
                actual_files: [],
              }));
            });
        }
      );
    });
  }

  function handleImageRemove(index) {
    if (window.confirm("are you sure you want to delete this image?")) {
      const newArr = [...state.uploaded_images];
      newArr.splice(index, 1);
      setState((state) => ({
        ...state,
        uploaded_images: newArr,
      }));
    } else {
      return;
    }
  }

  async function handleSpaceAdd() {
    await setState((state) => ({ ...state, loading: true }));
    await handleDataBaseAddSpace();
    const timeout = setTimeout(() => {
      navigate(`/audit/${parent_tradesman}/edit/${job_id}`);
    }, 1000);
    setState((state) => ({ ...state, loading: false }));
  }

  React.useEffect(() => {
    db.collection("Employee_Jobs")
      .doc(parent_tradesman)
      .collection(currentUser.uid)
      .doc(job_id)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          job_data: r.data(),
        }));
        console.log(r.data());
      });
  }, []);

  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
    }));
  };

  async function handleSpaceAddAndAssetAdd() {
    await setState((state) => ({ ...state, loading: true }));
    await handleDataBaseAddSpaceForSpaceAdd();
    setState((state) => ({ ...state, loading: false }));
  }

  return (
    <>
      

      <Header />
      <div className="SpaceAdd_HEader">
        <Button
          onClick={(e) => {
            handleSpaceAdd();
          }}
          style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
          size="small"
        >
          SAVE
        </Button>
        <div>Add Space</div>
      </div>
      <div className="AuditSpaceAdd_content">
        <Card className="AuditSpaceAdd_card">
          <div className="AuditHome_sub_heading">
            <div>New Space</div>
          </div>

          <div className="AuditHome_img_parent">
            {state.files[0] ? (
              <>
                <div className="SpaceAdd_images">
                  {state.uploaded_images &&
                    state.uploaded_images.map((file, index) => (
                      <div style={{ marginLeft: "10px" }}>
                        {imageComponent(file.url, index)}
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <img
                  src={placeholder}
                  alt="img upload"
                  className="AuditHome_img"
                />
              </>
            )}
          </div>
          <div className="dialog_box_select_file">
            <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              capture="environment"
              multiple
              className={"dialog_box_select_file"}
              onChange={handleChange}
            />

            <IconButton size="small">
              <Publish
                style={{ color: "rgb(18, 100, 100)" }}
                onClick={(e) => {
                  handleImageUpload();
                }}
              />
            </IconButton>
          </div>

          <div className="SpaceAdd_Textfields">
            <TextField
              fullWidth
              style={{ marginTop: "10px" }}
              size="small"
              label="Space Display Name"
              placeholder="Master Bedroom..."
              onChange={(e) => {
                handleStringChange(e, "display_name");
              }}
              value={state.display_name}
            />
            <TextField
              fullWidth
              style={{ marginTop: "10px" }}
              size="small"
              label="Space Description"
              placeholder="First bedroom on left as you walk through the front door..."
              multiline
              rows={5}
              onChange={(e) => {
                handleStringChange(e, "description");
              }}
              value={state.description}
            />

            <div className="SpaceAdd_measurment_section">
              <div className="SpaceAdd_measurments">
                <TextField
                  fullWidth
                  style={{ marginTop: "10px" }}
                  size="small"
                  label="Length"
                  onChange={(e) => {
                    handleStringChange(e, "length");
                  }}
                  value={state.length}
                />
                <TextField
                  fullWidth
                  style={{ marginTop: "10px" }}
                  size="small"
                  label="Width"
                  onChange={(e) => {
                    handleStringChange(e, "width");
                  }}
                  value={state.width}
                />
                <TextField
                  fullWidth
                  style={{ marginTop: "10px" }}
                  size="small"
                  label="Height"
                  onChange={(e) => {
                    handleStringChange(e, "height");
                  }}
                  value={state.height}
                />
              </div>
              <div className="SpaceAdd_measurments2">
                <FormControl variant="outlined" style={{ marginTop: "10px" }}>
                  <InputLabel>measurement</InputLabel>
                  <Select
                    fullWidth
                    native
                    label="measurement"
                    size="small"
                    onChange={(e) => {
                      handleStringChange(e, "measurement_type");
                    }}
                    value={state.measurement_type}
                  >
                    <option value="Metres">Metres </option>
                    <option value="Feet">Feet </option>
                    <option value="Centimetres">Centimetres </option>
                    <option value="Millimetres">Millimetres </option>
                    <option value="Inches">Inches </option>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="AuditHome_sub_heading" style={{ marginTop: "20px" }}>
            <div>Space Assets</div>
          </div>
          <div className="AuditHome_asset_add">
            <Fab
              style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
              size="small"
              onClick={(e) => {
                handleSpaceAddAndAssetAdd();
              }}
            >
              <Add />
            </Fab>
          </div>
        </Card>

      </div>

      {state.loading && (
        <div className="SpaceAdd_loading_background">
          <div className="SpaceAdd_loading_sub">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
        </div>
      )}

      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SpaceAdd;
