export const extraSpaces = [
  {
    title: "Heating & Cooling Assets",
    path: "heating_and_cooling_assets",
    description:
      "Assets that belong to the whole property or structure, such as in floor heating units or ducted heating and airconditioning units",
  },
  {
    title: "Energy Assets",
    path: "energy_assets",
    description:
      "Assets that belong to the whole property or structure, such as a solar array, battery back up or generator ",
  },
  {
    title: "Utilities & Other Assets",
    path: "utilities_and_other_assets",
    description:
      "Assets that belong to the whole property or structure, such as hot water services and other assets the whole property benifits from such as a security system, sprinker system, smoke alarms or speaker system",
  },
];
