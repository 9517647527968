import React from 'react'
import AuditList from '../Audit List/AuditList'
import Header from '../Header/Header'

function Dashboard() {
  return (<>
  <Header />

  <div className='header_page_title'> 
    <div>Dashboard</div>
  </div>
  <AuditList />
  </>
  
  )
}

export default Dashboard