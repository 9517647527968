import {
  Add,
  ArrowBackIos,
  ArrowUpward,
  Check,
  InsertDriveFile,
  NavigateNext,
  Send,
} from "@mui/icons-material";
import { Button, Card, CircularProgress, Fab, IconButton } from "@mui/material";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";
import "./Styles/AuditHome.css";
import placeholder from "../Images/house.png";
import { db } from "../../firebase";
import { useAuth } from "../../AuthContext";
import firebase from "firebase";

function AuditHome() {
  /* use param variables */
  const { job_id, related_job } = useParams();
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  /* state variables */
  const [job, setJob] = React.useState({});
  const [parent_tradesman, setParent_tradesman] = React.useState("");

  const [state, setState] = React.useState({
    loading: false,
    hidden: true,
  });

  /* data fetch UseEffect */
  React.useEffect(async () => {
    const userData = await db.collection("users").doc(currentUser.uid).get();
    const user = userData.data();
    db.collection("Employee_Jobs")
      .doc(user.tradesman)
      .collection(currentUser.uid)
      .doc(job_id)
      .get()
      .then((r) => {
        setJob(r.data());
        console.log(r.data());
        setParent_tradesman(user.tradesman);
      });
  }, []);

  const address = job.selected_property_address
    ? job.selected_property_address
    : {};
  const address_line_1 = address.address_line_1 ? address.address_line_1 : "";
  const address_line_2 = address.address_line_2 ? address.address_line_2 : "";
  const address_city_state =
    address.address_city && address.address_state
      ? `${address.address_city}, ${address.address_state}`
      : "";
  const address_country = address.address_country
    ? address.address_country
    : "";

  const encoderUrl = encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${address_line_1}${address_line_2}${address_city_state}${address_country}`
  );

  async function handleWorkComplete() {
    if (
      window.confirm(
        "Are you sure you want to mark the work at this property complete?"
      )
    ) {
      console.log(job.related_job);

      await setState((state) => ({
        ...state,
        loading: true,
      }));
      const cloudFunction = await firebase
        .functions()
        .httpsCallable("HandleMarkingTradesmanEmployeeJobComplete");

      await cloudFunction({ job_id: job.related_job })
        .then((e) => {
          console.log(e.data);
          navigate("/dashboard");
          setState((state) => ({
            ...state,
            loading: false,
          }));
          return e.data;
        })
        .catch((e) => {
          console.log(e);
          setState((state) => ({
            ...state,
            loading: false,
          }));
          return e;
        });
    } else {
      setState((state) => ({
        ...state,
        loading: false,
      }));
      return;
    }
  }

  return (
    <>
      <Header />

      <div className="header_page_title">
        <div>Job Overview</div>
      </div>
      <div className="AuditHome_master_content_div">
        {state.loading && (
          <div className="AuditHome-loading">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
        )}

        <div className="AuditHome_master_content_card">
          <div className="AuditHome_top_div_a_i">
            <Card className="AuditHome_sub_card">
              <div className="AuditHome_sub_heading">
                <div>Property Image</div>
              </div>
              <div className="AuditHome_image_div">
                <img
                  src={job.access_image ? job.access_image : placeholder}
                  alt="Placeholder Image"
                  className="AuditHome_image_img"
                />
              </div>
            </Card>
            <Card className="AuditHome_sub_card">
              <div className="AuditHome_sub_heading">
                <div>Property Address</div>
              </div>
              <div className="AuditHome_address_display">
                <div className="AuditHome_address_display_left">
                  <div className="AuditHome_address_display_left_rotate">
                    <IconButton
                      onClick={(e) => {
                        window.open(encoderUrl);
                      }}
                    >
                      <Send style={{ color: "rgb(18, 100, 100)" }} />
                    </IconButton>
                  </div>
                </div>

                <div>
                  <div className="AduitHome_address_line">
                    {address_line_1 && address_line_1}
                  </div>
                  <div className="AduitHome_address_line">
                    {" "}
                    {address_line_2 && address_line_2}
                  </div>
                  <div className="AduitHome_address_line">
                    {" "}
                    {address_city_state && address_city_state}
                  </div>
                  <div className="AduitHome_address_line">
                    {address_country && address_country}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div>
            <Card className="AuditHome_sub_card_bottom">
              <div className="AuditHome_sub_heading">
                <div>Access Notes</div>
              </div>
              <div className="AuditHome_notes_message">
                {job.access_notes ? job.access_notes : ""}
              </div>
            </Card>
          </div>

          {/* conduct audit button */}
        </div>

        <div className={state.hidden ? "fab-buttons-hidden" : "fab-buttons"}>
          <div className={state.hidden ? "arrow-button" : "arrow-button-shown"}>
            <Fab
              style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
              onClick={(e) => {
                setState((state) => ({ ...state, hidden: !state.hidden }));
              }}
            >
              <ArrowBackIos />
            </Fab>
          </div>
          <div className={"actions"}>
            <Fab
              onClick={(e) => {
                navigate(`/add_asset/${job_id}/${related_job}`);
              }}
              style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
            >
              <Add />
            </Fab>
            <Fab
              onClick={(e) => {
                handleWorkComplete();
              }}
              style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
            >
              <Check />
            </Fab>
          </div>
        </div>

        {/* 

        



          <Button
            style={{
              marginTop: "5px",
              position: "relative",
              width: "fit-content",
              color: "white",
              backgroundColor: "rgb(18, 100, 100)",
              borderRadius: "10px",
            }}
            onClick={(e) => {
              navigate(`/audit/${parent_tradesman}/edit/${job_id}`);
            }}
          >
            Start Audit
          </Button>
*/}
      </div>
    </>
  );
}

export default AuditHome;
