import {
  Add,
  ArrowBackIos,
  Cancel,
  CheckBox,
  Dvr,
  Publish,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Card,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Styles/SpaceAdd.css";
import placeholder from "../Images/house.png";
import { db, storage } from "../../firebase";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";

/* to do list */
/* pull the default values from the database */
/* handle updatading instead of adding to the database */

function WholePropertyAssets() {
  const { parent_tradesman, job_id, group } = useParams();
  const { currentUser } = useAuth();

  const [state, setState] = React.useState({
    display_name: "",
    images: [],
    description: "",
    length: "",
    width: "",
    height: "",
    measurement_type: "Metres",
    files: [],
    job_data: {},
    uploaded_images: [],
    errorOpen: false,
    successOpen: false,
    successMessage: "",
    errorMessage: "",
    actual_files: [],
    loading: false,
    space_data: {},
    mergedImages: [],
    assets: [],
  });

  function handleStringChange(e, field) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  React.useEffect(() => {

    db.collection("Employee_Jobs")
      .doc(parent_tradesman)
      .collection(currentUser.uid)
      .doc(job_id)
      .get()
      .then((r) => {
    db.collection('new_audits')
    .doc(r.data().related_job)
    .collection('assets')
    .where('realted_space', '==', group)
    .onSnapshot((snapshot) => {
        setState((state) => ({
            ...state, 
            assets: snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
        }))
        console.log(snapshot)
    })
      });




    
}, [state.job_data])





  const handleChange = async (e) => {
    const files = e.target.files;
    const holdingArr = [...state.files];
    const holdingArr2 = [...state.actual_files];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = files[i];
      const newUrl = URL.createObjectURL(file);
      await holdingArr.push({ ...file, url: newUrl });
      await holdingArr2.push(file);
    }
    await setState((state) => ({
      ...state,
      files: holdingArr,
      actual_files: holdingArr2,
      mergedImages: state.mergedImages.concat(holdingArr),
    }));
  };

  const navigate = useNavigate();

  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
    }));
  };

  function handleTitleDecide() {
    if (group === "heating_and_cooling_assets") {
      return "Heating & Cooling Assets";
    } else if (group === "energy_assets") {
      return "Energy Assets";
    } else if (group === "utilities_and_other_assets") {
      return "Utilities & Other Assets";
    } else {
      return "";
    }
  }

  return (
    <>
      <Header />
      <div className="header_page_title">
        <div>{handleTitleDecide()}</div>
      </div>
      <div className="AuditSpaceAdd_content">
        <Card className="AuditSpaceAdd_card">
          <div>
            <IconButton
              size="small"
              onClick={(e) => {
                navigate(`/audit/${parent_tradesman}/edit/${job_id}`);
              }}
            >
              <ArrowBackIos style={{ color: "rgb(18 100, 100)" }} />
            </IconButton>
          </div>

          <div
            style={{
              borderBottom: "solid",
              borderColor: "lightgrey",
              borderWidth: "0.5px",
              paddingBottom: "10px",
              fontWeight: "600",
            }}
          >
           {handleTitleDecide()}
          </div>

          <div>
            {state.assets &&
              state.assets.map((asset) => (
                <>
                  <ListItem
                    key={asset.id}
                    button
                    style={{ padding: "5px" }}
                    onClick={(e) => {
                      navigate(
                        `/audit/${parent_tradesman}/edit/${job_id}/edit_space/${group}/edit_asset/${asset.id}`
                      );
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={
                          asset.images && asset.images[0] && asset.images[0].url
                        }
                        alt={asset.display_name}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={asset.display_name}
                      secondary={asset.description}
                    />
                  </ListItem>
                </>
              ))}
          </div>
          <div className="AuditHome_asset_add">
            <Fab
              style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
              size="small"
              onClick={(e) => {
                navigate(
                  `/audit/${parent_tradesman}/edit/${job_id}/edit_space/${group}/add_asset/`
                );
              }}
            >
              <Add />
            </Fab>
          </div>
        </Card>
      </div>

      {state.loading && (
        <div className="SpaceAdd_loading_background">
          <div className="SpaceAdd_loading_sub">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
        </div>
      )}

      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default WholePropertyAssets;
