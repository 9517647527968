import { TextField } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../AuthContext";
import { db } from "../../../../firebase";
import "../Styles/Slides.css";

function Review() {
  // hooks
  const { currentUser } = useAuth();
  const { job_id } = useParams();

  const [state, setState] = React.useState({
    display_name: '', 
    description: '', 
  installer: '', 
    install_date: new Date(),  
    warranty: '',
     product_type: '', 
    serial_number: '', 
    manufactuer: '', 
    product_code: '',
   
   
    images: [], 
  })

  // useEffect
  React.useEffect(() => {
    db.collection("new-assets")
      .doc(currentUser.uid)
      .collection("assets")
      .doc(job_id)
      .get()
      .then((r) => {
        if (r.exists) {
          setState((state) => ({
            ...state,
            display_name: r.data().display_name ? r.data().display_name : '',
description: r.data().description ? r.data().description : '',
images: r.data().images ? r.data().images : [],
install_date: r.data().install_date ? r.data().install_date.toDate().toLocaleDateString() : null,
installer: r.data().installer ? r.data().installer : '',
manufactuer: r.data().manufactuer ? r.data().manufactuer : '',
product_code: r.data().product_code ? r.data().product_code : '',
product_type: r.data().product_type ? r.data().product_type : '',
serial_number: r.data().serial_number ? r.data().serial_number : '',
warranty: `${r.data().warranty_amount ? r.data().warranty_amount : ''} ${r.data().warranty_period ? r.data().warranty_period : ''}`
          }));
        } else {
          return;
        }
      });
  }, [currentUser, job_id]);
  


  const fields = [
    {
      title: "Basic Details",
      fields: [
        { label: "Display Name", value: state.display_name },
        { label: "Description", value: state.description },
      ],
    },
    {
      title: "Installation Details",
      fields: [
        { label: "Installer Name", value: state.installer },
        { label: "Installation Date", value: state.install_date },
      ],
    },
    {
      title: "Warranty Details",
      fields: [{ label: "Warranty Period", value: state.warranty }],
    },
    {
      title: "Product Details",
      fields: [
        { label: "Product Type", value: state.product_type },
        { label: "Serial Number", value: state.serial_number },
        { label: "Manufactuer", value: state.manufactuer },
        { label: "Product Code", value: state.product_code },
      ],
    },
  ];
  return (
    <div className="Review">
      <div className="title">Review Details</div>

      {fields &&
        fields.map((item, ind) => (
          <div key={ind} className="section">
            <div className="subtitle">
              <div className="text">{item.title}</div>
              <div className="line" />
            </div>
            <div className="fields">
              {item.fields.map((field, index) => (
                <div key={index} className="fullwidth">
                  <TextField label={field.label} value={field.value} fullWidth disabled />
                </div>
              ))}
            </div>
          </div>
        ))}


<div className="photos-uploaded">
            <div className="subtitle">
              <div className="text">Photos</div>
              <div className="line" />
            </div>


            {state.images &&
          state.images.map((image) => {
            return (
              <div className="image">
                <img alt='' src={image.url} />
              </div>
            );
          })}

            
          </div>

    

    
    </div>
  );
}

export default Review;
