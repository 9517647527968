import { AddAPhoto, Clear } from "@mui/icons-material";
import {
  Alert,
  CircularProgress,
  Fab,
  IconButton,
  Snackbar,
} from "@mui/material";
import React from "react";
import { db, storage } from "../../../../firebase";
import "../Styles/Slides.css";
import { useAuth } from "../../../../AuthContext";
import { useParams } from "react-router-dom";

function Photos() {
  // hooks
  const { currentUser } = useAuth();
  const { job_id } = useParams();

  // state variables
  const [state, setState] = React.useState({
    loading: false,
    images: [],
    errorOpen: false,
    errorMessage: "",
    successMessage: "",
    successOpen: false,
  });

  // use Effect to set Default values
  React.useEffect(() => {
    db.collection("new-assets")
      .doc(currentUser.uid)
      .collection("assets")
      .doc(job_id)
      .get()
      .then((r) => {
        if (r.exists) {
          setState((state) => ({
            ...state,
            images: r.data() && r.data().images ? r.data().images : [],
          }));
        } else {
          return;
        }
      });
  }, [currentUser.uid, job_id]);

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  async function handleInput(e) {
    await setState((state) => ({
      ...state,
      loading: true,
    }));
    const data = await handleImageUpload(e);
    const imgArr = [...state.images];
    for (let i = 0; i < data.length; i++) {
      const img = data[i];
      imgArr.push(img);
    }
    await db
      .collection("new-assets")
      .doc(currentUser.uid)
      .collection("assets")
      .doc(job_id)
      .set({ images: imgArr }, { merge: true })
      .then((r) => {
        setState((state) => ({
          ...state,
          loading: false,
          images: imgArr,
          successMessage: "Successfully saved images...",
          successOpen: true,
          errorOpen: false,
          errorMessage: "",
        }));
      })
      .catch((err) => {
        console.log(err);
        setState((state) => ({
          ...state,
          loading: false,
          successMessage: "",
          successOpen: false,
          errorOpen: true,
          errorMessage: "Failed to save images...",
        }));
      });
  }

  async function handleImageUpload(e) {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      const imageName = `${newImage.name}_${Math.floor(
        Math.random() * (999 - 100 + 1) + 100
      )}`;
      await storage
        .ref(`${currentUser.uid}/asset_images/${imageName}`)
        .put(newImage);
      const url = await storage
        .ref(`${currentUser.uid}/asset_images/`)
        .child(imageName)
        .getDownloadURL();
      await arr.push({ title: imageName, url: url });
    }
    return arr;
  }

  function handleImageRemove(img) {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    const url = img.url;
    const arr = [...state.images];
    const index = arr.findIndex((item) => item.url === url);
    arr.splice(index, 1);

    db.collection("new-assets")
    .doc(currentUser.uid)
    .collection("assets")
    .doc(job_id)
    .update({
      images: arr
    }).then((r) => {
      setState((state) => ({
        ...state, 
        loading: false, 
        images: arr, 
        errorOpen: false, 
        errorMessage: '', 
        successOpen: true, 
        successMessage: 'Successfully removed image...'
      }))
    }).catch((err) => {
      setState((state) => ({
        ...state, 
        loading: false, 
        errorOpen: true, 
        errorMessage: 'Failed to delete image...', 
        successOpen: false, 
        successMessage: ''

      }))

    })
  }

  // snackbar handling
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
      errorOpen: false,
    }));
  };

  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");

  return (
    <div className="slide-photos">
      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      {state.loading && (
        <div className="loading">
          <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
        </div>
      )}
      <div className="title">Photos</div>
      <div className="photos-uploaded">
        {state.images &&
          state.images.map((image) => {
            return (
              <div className="image">
                <IconButton
                  className="icon-button"
                  onClick={(e) => {
                    handleImageRemove(image);
                  }}
                >
                  <Clear style={{ color: "rgb(18, 100, 100)" }} />
                </IconButton>
                <img alt='' src={image.url} />
              </div>
            );
          })}
      </div>
      <div className="upload-fab">
        <input
          accept="image/*"
          ref={hiddenFileInput}
          id="icon-button-file"
          type="file"
          multiple
          className={"dialog_box_select_file"}
          onChange={handleInput}
          style={{ display: "none" }}
        />

        <Fab
          onClick={(e) => {
            handleClick();
          }}
          size="medium"
          style={{ color: "white", backgroundColor: "rgb(18, 100, 100)" }}
        >
          <AddAPhoto />
        </Fab>
      </div>
    </div>
  );
}

export default Photos;
