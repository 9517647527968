import { Alert, FormControl, InputLabel, Select, Snackbar, TextField } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../AuthContext";
import { db } from "../../../../firebase";
import "../Styles/Slides.css";

function WarrantyDetails() {
  // hooks
  const { currentUser } = useAuth();
  const { job_id } = useParams();

  // state variables
  const [state, setState] = React.useState({
    warranty_amount: "",
    warranty_period: "",
    errorOpen: false, 
    errorMessage: '', 
  });

  // handle snackbar close 
const [vertical] = React.useState("bottom");
const [horizontal] = React.useState("right");
const handleSnackClose = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }
  setState((state) => ({
    ...state,
    errorOpen: false,
    errorMessage: "",
  }));
};



// handle autosave
function handleBlur () { 
  db.collection("new-assets")
  .doc(currentUser.uid)
  .collection("assets")
  .doc(job_id)
  .set({
    warranty_amount: state.warranty_amount,
    warranty_period: state.warranty_period,
  }, {merge: true}).then((r) => {
    setState((state) => ({
      ...state, 
      errorOpen: false, 
      errorMessage: '', 
    }))
  }).catch((er) => {
    console.log(er)
    setState((state) => ({
      ...state, 
      errorOpen: true, 
      errorMessage: 'Failed to save progress...', 
    }))

  })
}

  // useEffect
  React.useEffect(() => {
    db.collection("new-assets")
      .doc(currentUser.uid)
      .collection("assets")
      .doc(job_id)
      .get()
      .then((r) => {
        if (r.exists) {
          setState((state) => ({
            ...state,
            warranty_amount: r.data().warranty_amount
              ? r.data().warranty_amount
              : "",
            warranty_period: r.data().warranty_period
              ? r.data().warranty_period
              : "",
          }));
        } else {
          return;
        }
      });
  }, [currentUser, job_id]);

  return (
    <div className="slide">
        <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className="title">Warranty Details</div>
      <div className="fields">
        <div className="thirty-seventy">
          <div className="thirty">
            <TextField 
            value={state.warranty_amount}
            onChange={((e) => {
              setState((state) => ({
                ...state,
                warranty_amount: e.target.value
              }))
            })} fullWidth label="Amount" placeholder="5" 
            onBlur={handleBlur}/>
          </div>
          <div className="seventy">
            <FormControl fullWidth style={{ marginBottom: "10px" }}>
              <InputLabel>Period</InputLabel>
              <Select
                fullWidth 
                value={state.warranty_period}
                onChange={((e) => {
                  setState((state) => ({
                    ...state,
                    warranty_period: e.target.value
                  }))
                })}
                native
                label="Period"
                size="small"
                onBlur={handleBlur}
              >
                <option value="Years">Years </option>
                <option value="Months">Months </option>
                <option value="Weeks">Weeks </option>
                <option value="Days">Days </option>
                <option value="Generations">Generations </option>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WarrantyDetails;
