import { Add } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  Fab,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { db } from "../../firebase";
import Header from "../Header/Header";
import { extraSpaces } from "./ExtraSpace";
import "./Styles/AuditSpaceAdd.css";
import placeholder from '../Images/house.png'

function AuditSpaceAdd() {
  const { parent_tradesman, job_id } = useParams();

  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [spaces, setSpaces] = React.useState([]);

  const link = `/audit/${parent_tradesman}/edit/${job_id}/add_space`;

  /* function to handle fetching existing spaces on this audit */
  React.useEffect(() => {
    db.collection("Employee_Jobs")
      .doc(parent_tradesman)
      .collection(currentUser.uid)
      .doc(job_id)
      .get()
      .then((r) => {
        const data = r.data();
        setJob(data)
        db.collection("new_audits")
          .doc(data.related_job)
          .collection("spaces")
          .onSnapshot((snapshot) => {
            setSpaces(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            );
          });
      });
  }, []);

  const [job, setJob]= React.useState({})


  function handleAuditComplete () { 
    if (window.confirm('Are you sure you want to complete this audit? once submited details cannot be edited')) {
      db.collection('new_audits')
      .doc(job.related_job)
      .update({
        complete: true,
        related_job: job_id, 
        related_tradesman: currentUser.uid, 
        parent_tradesman: parent_tradesman, 
      
      }).then ((r) => {
        navigate('/dashboard')
      }).catch((e) => {
        window.alert('Failed to complete audit')
        console.log(e, 'error')
      })
    } else {
      console.log('cancel')
    }
  }

  return (
    <>
      <Header />
      <div className="header_page_title">
        <div>Audit Edit</div>
      </div>
      <div className="AuditSpaceAdd_content">
        <Card className="AuditSpaceAdd_card">
          <div className="AuditHome_sub_heading">
            <div>Spaces</div>
          </div>
          <div className="AuditHome_spaces_list">
            {spaces &&
              spaces.map((space) => (
                <ListItem
                  button
                  onClick={(e) => {
                    navigate(
                      `/audit/${parent_tradesman}/edit/${job_id}/edit_space/${space.id}`
                    );
                  }}
                  key={space.id}
                  style={{ padding: "5px" }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={
                        space.images && space.images[0] && space.images[0].url
                      }
                      alt={space.display_name}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={space.display_name}
                    secondary={space.description}
                  />
                </ListItem>
              ))}
              {extraSpaces &&
              extraSpaces.map((space, index) => (
                <ListItem
                  button
                  onClick={(e) => {
                    navigate(
                      `/audit/${parent_tradesman}/edit/${job_id}/whole_property/${space.path}`
                    );
                  }}
                  key={space.index}
                  style={{ padding: "5px" }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={
                        placeholder
                      }
                      alt={space.title}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={space.title}
                    secondary={space.description}
                  />
                </ListItem>
              ))}
          </div>
          <div className="AuditHome_add_div">
            <Fab
              size="small"
              style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
              onClick={(e) => {
                navigate(link);
              }}
            >
              <Add />
            </Fab>
          </div>
        </Card>
        <div></div>
      </div>
      <Button style={{color:'rgb(18, 100, 100)', backgroundColor:"white", margin:'5px'}}
      onClick={((e) => handleAuditComplete())}>Complete Audit</Button>
    </>
  );
}

export default AuditSpaceAdd;
