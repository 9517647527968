import { Alert, Snackbar, TextField } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../AuthContext';
import { db } from '../../../../firebase';
import '../Styles/Slides.css'

function BasicDetails() {
// hooks 
const {currentUser} = useAuth();
const {job_id} = useParams();

// state variables
  const [state, setState] = React.useState({
    display_name: '', 
    description: '',
    errorOpen: false, 
    errorMessage: '', 
  });

// handle snackbar close 
const [vertical] = React.useState("bottom");
const [horizontal] = React.useState("right");
const handleSnackClose = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }
  setState((state) => ({
    ...state,
    errorOpen: false,
    errorMessage: "",
  }));
};



// handle autosave
function handleBlur () { 
  db.collection("new-assets")
  .doc(currentUser.uid)
  .collection("assets")
  .doc(job_id)
  .set({
    display_name: state.display_name, 
    description: state.description,
  }, {merge: true}).then((r) => {
    setState((state) => ({
      ...state, 
      errorOpen: false, 
      errorMessage: '', 
    }))
  }).catch((er) => {
    console.log(er)
    setState((state) => ({
      ...state, 
      errorOpen: true, 
      errorMessage: 'Failed to save progress...', 
    }))

  })
}


// useEffect 

React.useEffect(() => {
  db.collection("new-assets")
  .doc(currentUser.uid)
  .collection("assets")
  .doc(job_id)
  .get()
  .then((r) => {
    if (r.exists) {
 setState((state) => ({
      ...state, 
    display_name: r.data().display_name ?  r.data().display_name : '', 
    description: r.data().description ? r.data().description : '' ,

    }))
    } else {
      return;
    }
   
  })

}, [currentUser, job_id])



  return (
    <div className='slide'>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>

      <div className='title'>
        Basic Details 
      </div>
      <div className='fields'>
        <div className='fullwidth'>
          <TextField value={state.display_name} onChange={((e) => {
            setState((state) => ({
              ...state, 
              display_name: e.target.value
            }))
          })}  label='Display Name' onBlur={handleBlur} fullWidth placeholder='Gas Hot Water Service...'/> 
        </div> 
        <div className='fullwidth'>
          <TextField value={state.description} onChange={((e) => {
            setState((state) => ({
              ...state, 
              description: e.target.value
            }))
          })}  label='Description' onBlur={handleBlur} fullWidth placeholder='Main hot water service for the house, located on the left of the house...' multiline minRows={3} maxRows={10}/> 
        </div> 

      </div>
      </div>
  )
}

export default BasicDetails