import React from "react";
import "./Styles/Header.css";
import logo from "../Images/tenacklogo.png";
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Logout } from "@mui/icons-material";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { sidebarData } from "./SidebarData";

function Header() {
  const [sidebar, setSidebar] = React.useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const navigate = useNavigate();
  async function handleLogout() {
    await auth.signOut().then((r) => {
      navigate("/");
    });
  }
  return (
    <>
      <div className="Header_body">
        <div className="header_body_left">
          <div className="header_body_left_img_div">
            <img
              src={logo}
              alt="tenack logo image"
              className="header_body_left_img_div_img"
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          </div>
        </div>
        <div className="header_body_right">
          <div className="header_body_right_sub">
            <IconButton onClick={showSidebar}>
              <MenuIcon style={{ color: "rgb(18, 100, 100)" }} />
            </IconButton>
          </div>
        </div>
      </div>

      <div className={sidebar ? "nav-menu active" : "nav-menu"}>
        <div className="sidebar_topbar">
          <div className="header_body_right_sub">
            <IconButton onClick={showSidebar}>
              <MenuIcon style={{ color: "white" }} />
            </IconButton>
          </div>
        </div>
        <div className="header_sidebar_list">
          {sidebarData &&
            sidebarData.map((item) => (
              <ListItem
                button
                onClick={(e) => {
                  navigate(item.path);
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        fontWeight: "600",
                        textTransform: "capitalize",
                        fontSize: "large",
                      }}
                    >
                      {item.title}
                    </Typography>
                  }
                  style={{ color: "white" }}
                />
              </ListItem>
            ))}

          <ListItem button onClick={handleLogout}>
            <ListItemIcon>{<Logout  style={{color:'white'}}/>}</ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontWeight: "600",
                    textTransform: "capitalize",
                    fontSize: "large",
                  }}
                >
                  Sign Out
                </Typography>
              }
              style={{ color: "white" }}
            />
          </ListItem>
        </div>
      </div>
    </>
  );
}

export default Header;
