import { Alert, CircularProgress, FormControl, InputLabel, Select, Snackbar, TextField } from "@mui/material";
import React from "react";
import "../Styles/Slides.css";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { useAuth } from "../../../../AuthContext";
import { useParams } from "react-router-dom";
import { db } from "../../../../firebase";
import firebase from "firebase";

function Installation() {
  // hooks
  const { currentUser } = useAuth();
  const { job_id, related_job } = useParams();

  // state variables

  const [state, setState] = React.useState({
    install_date: new Date(),
    installer: "",
    errorOpen: false,
    errorMessage: "",
    locations: [{id: 'heating_cooling', display_name: 'Heating & Cooling'}], 
    loading: true, 
    location: 'heating_cooling'
  });

  // handle snackbar close
  const [vertical] = React.useState("bottom");
  const [horizontal] = React.useState("right");
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      errorMessage: "",
    }));
  };

  function handleDateChange(e) {
    setState((state) => ({
      ...state,
      install_date: e,
    }));
  }

  // useEffect

  React.useEffect(() => {
    db.collection("new-assets")
      .doc(currentUser.uid)
      .collection("assets")
      .doc(job_id)
      .get()
      .then((r) => {
        if (r.exists) {
          const date =  r.data().install_date ? r.data().install_date.toDate() : new Date()
          setState((state) => ({
            ...state,
            install_date: date,
            installer: r.data().installer ? r.data().installer : "",
            location: r.data().location ? r.data().location : "",

          }));
        } else {
          return;
        }
      });
  }, [currentUser, job_id]);

  
  // handle autosave
  function handleBlur() {
    db.collection("new-assets")
      .doc(currentUser.uid)
      .collection("assets")
      .doc(job_id)
      .set(
        {
          install_date: state.install_date,
          installer: state.installer,
          location: state.location
        },
        { merge: true }
      )
      .then((r) => {
        setState((state) => ({
          ...state,
          errorOpen: false,
          errorMessage: "",
        }));
      })
      .catch((er) => {
        console.log(er);
        setState((state) => ({
          ...state,
          errorOpen: true,
          errorMessage: "Failed to save progress...",
        }));
      });
  }

  function handleSave (location) {
      db.collection("new-assets")
        .doc(currentUser.uid)
        .collection("assets")
        .doc(job_id)
        .set(
          {
            location
          },
          { merge: true }
        )
        .then((r) => {
          setState((state) => ({
            ...state,
            errorOpen: false,
            errorMessage: "",
          }));
        })
        .catch((er) => {
          console.log(er);
          setState((state) => ({
            ...state,
            errorOpen: true,
            errorMessage: "Failed to save progress...",
          }));
        });
    
  }



  React.useEffect(() => {
handleFetchSpaces()
  }, [job_id, related_job])
  

  async function handleFetchSpaces () {

    const cloudFunction = await firebase
    .functions()
    .httpsCallable("TenackFieldSpaceReturn");

  await cloudFunction({job_id: related_job}).then((r) => {
    const holders = [
      {id: 'heating_cooling', display_name: 'Heating & Cooling',},
      {id: 'energy', display_name: 'Energy',},
      {id: 'utilities', display_name: 'Utilities',},
    ]
    if (r.data.success) {
 setState((state) => ({
      ...state, 
      locations: holders.concat(r.data.results), 
      loading: false,
    }))
    } else {
      setState((state) => ({
        ...state, 
        loading: false, 
        errorOpen: true, 
        errorMessage: r.data.message
      }))

    }

   

  })
  }


  return (
    <div className="slide"> 
    
    {state.loading &&
        <div className="loading">
          <CircularProgress style={{color:'rgb(18, 100, 100)'}}/> 
          </div>}
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>
      <div className="title">Installation Details</div>
     
      <div className="fields">

       
        <div className="fullwidth">
        <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel>Installation Location</InputLabel>
                  <Select
                  
                    fullWidth
                    native
                    label="Installation Location"
                    onChange={(e) => {
                     setState((state) => ({
                      ...state, 
                      location: e.target.value
                     }))
                     handleSave (e.target.value)
                    }}
                    value={state.location}
                  >

                    {state.locations && state.locations.map((location) => (

                      <option value={location.id}>{location.display_name} </option>
                    ))}
                    
                  
                  </Select>
                </FormControl> 

        </div>

        <div className="fullwidth">
          <TextField
            label="Installer Name"
            fullWidth
            placeholder="Mr Hot Water Service..."
            value={state.installer}
            onBlur={handleBlur}
            onChange={((e) => {
              setState((state) => ({
                ...state, 
                installer: e.target.value, 
              }))
            })}
          />
        </div>
        <div className="fullwidth">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              style={{ marginTop: "10px" }}
              id="date-picker-inline"
              label="Installation Date"
              formatDate={(date) => DateFnsUtils(date).format("YYYY-MM-DD")}
              fullWidth={true}
              value={state.install_date}
              onBlur={handleBlur}
              inputVariant="outlined"
              // size="small"
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>{" "}
        </div>
      </div>
    </div>
  );
}

export default Installation;
