import {
  Avatar,
  createMuiTheme,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import "./Styles/AuditList.css";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { db } from "../../firebase";
import { useAuth } from "../../AuthContext";
import { handleAddressString } from "../Hookers/HandleAddressString";
import { useNavigate } from "react-router-dom";

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      light: "rgb(18, 100, 100)",
      main: "rgb(18, 100, 100)",
      dark: "rgb(18, 100, 100)",
      contrastText: "#ffffff",
    },
  },
});
function AuditList() {
  const { currentUser } = useAuth();

  const [state, setState] = React.useState({
    date: new Date(),
    jobs: [],
  });

  function handleDateChange(date) {
    setState((state) => ({
      ...state,
      date,
    }));
  }

  const lowerDate = new Date(state.date);
  const upperDate = new Date(state.date);
  const lowerDateZero = lowerDate.setHours(0, 0, 0, 0);

  const upperDateZero = upperDate.setDate(upperDate.getDate() + 1);
  const upperDateZeroTwo = upperDate.setHours(0, 0, 0, 0);
  /* function to fetch the users docs on that day */

  async function handleDocumentFetch() {
    const userData = await db.collection("users").doc(currentUser.uid).get();
    const user = userData.data();

    db.collection("Employee_Jobs")
      .doc(user.tradesman)
      .collection(currentUser.uid)
      .get()
      .then((r) => {
        setState((state) => ({
          ...state,
          jobs: r.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        }));
        console.log(r.docs.map((doc) => doc.data()));
      });
  }

  React.useEffect(() => {
    handleDocumentFetch();
  }, []);
  React.useEffect(() => {
    handleDocumentFetch();
  }, [state.date]);

  const navigate = useNavigate();

  return (
    <>
     
      <div className="AuditList_content">
        <div className="AuditList_sub_div">
         
          <div className="AuditList_selected_bookings_list_title">
            Upcoming Jobs
          </div>
          <div className="AuditList_selected_bookings_list">
            {state.jobs &&
              state.jobs.map((job) => (
                <>
                  {!job.complete ? (
                    <>
                      <ListItem
                        key={job.related_job}
                        button
                        onClick={(e) => {
                          
                          navigate(`/audit/${job.id}/${job.related_job}`);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar src={""} alt={"Property Image"} />{" "}
                          {/* make image from database when available */}
                        </ListItemAvatar>
                        <ListItemText
                          primary={job.title && job.title}
                          secondary={handleAddressString(
                            job.selected_property_address &&
                              job.selected_property_address
                          )}
                        />
                      </ListItem>
                    </>
                  ) : (
                    <>
                      <ListItem key={job.related_job}>
                        <ListItemAvatar>
                          <Avatar src={""} alt={"Property Image"} />{" "}
                          {/* make image from database when available */}
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              style={{
                                color: "gray",
                                textDecoration: "line-through",
                              }}
                            >
                              {job.title && job.title}
                            </Typography>
                          }
                          secondary={handleAddressString(
                            job.selected_property_address &&
                              job.selected_property_address
                          )}
                        />
                      </ListItem>
                    </>
                  )}
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AuditList;
