//import firebase from "firebase/compat";
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDJ4Y241KaY_gPr4Q_bLUzTxmRUJP7Y4Sk",
  authDomain: "tenack-9d967.firebaseapp.com",
  projectId: "tenack-9d967",
  storageBucket: "tenack-9d967.appspot.com",
  messagingSenderId: "634002280728",
  appId: "1:634002280728:web:78781f37eedfb78a64ce6d",
  measurementId: "G-W7NLRB81XZ",
});
export const auth = app.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

export default app;
