import { Alert, Button, Card, Snackbar, TextField } from "@mui/material";
import React from "react";
import "./Styles/Login.css";
import logo from "../Images/tenacklogo.png";
import firebase from "firebase";
import { useAuth } from "../../AuthContext";
import { auth, db } from "../../firebase";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Login() {
  const [state, setState] = React.useState({
    errorOpen: false,
    errorMessage: "",
    successOpen: false,
    successMessage: "",
    password: "",
    email: "",
    loading: false,
  });

  function handleTextChange(e, field) {
    setState((state) => ({
      ...state,
      [field]: e.target.value,
    }));
  }

  const navigate = useNavigate();

  /* function to handle users logining in */
  async function handleUserLogin() {
    /* login the user */
    try {
      const user = await auth.signInWithEmailAndPassword(
        state.email,
        state.password
      );

      db.collection("users")
        .doc(user.user.uid)
        .get()
        .then((r) => {
          const data = r.data();
          if (data.user_type === "tradesman_employee") {
            setState((state) => ({
              ...state,
              errorOpen: false,
              errorMessage: "",
              successOpen: true,
              successMessage: "Successfully Logged in",
            }));
            /* navigate */
            navigate("/dashboard");
          } else {
            setState((state) => ({
              ...state,
              errorOpen: true,
              errorMessage: "User Is not an Audit Account",
            }));
            auth.signOut();
          }
        });
    } catch {
      setState((state) => ({
        ...state,
        errorOpen: true,
        errorMessage: "Failed to sign in",
      }));
    }

    //   console.log(r.user.uid)
    /* check the user data if not 'tradesmanUser' handle logout */
    /* set error */

    /* if good to go navigate to dashboard.  */
  }

  /* function to handle closing snackbar */
  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
    }));
  };

  return (
    <div className="Login_master_div">
      <div className="Login_sub_div">
        <Card className="Login_card">
          <div className="Login_card_logo_div">
            <img src={logo} alt="tenack logo" className="Login_card_img" />
          </div>
          <div className="Login_card_subheading">
            <div>Audit Login</div>
          </div>

          <TextField
            label="email"
            fullWidth
            style={{ marginTop: "5px", marginBottom: "5px" }}
            value={state.email}
            onChange={(e) => {
              handleTextChange(e, "email");
            }}
          />
          <TextField
            label="password"
            type={"password"}
            fullWidth
            style={{ marginTop: "5px", marginBottom: "5px" }}
            value={state.password}
            onChange={(e) => {
              handleTextChange(e, "password");
            }}
          />
          <div className="Login_card_submit_div">
            <Button
              style={{ backgroundColor: "rgb(18, 100, 100)", color: "white" }}
              size="small"
              onClick={(e) => {
                handleUserLogin();
              }}
            >
              Login
            </Button>
          </div>
        </Card>
      </div>

      <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="error">
          {state.errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;
