import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Alert, Button, CircularProgress, LinearProgress, Snackbar } from "@mui/material";
import React from "react";
import Header from "../../Header/Header";
import BasicDetails from "./Slides/BasicDetails";
import Installation from "./Slides/Installation";
import Photos from "./Slides/Photos";
import ProductDetails from "./Slides/ProductDetails";
import Review from "./Slides/Review";
import WarrantyDetails from "./Slides/WarrantyDetails";
import "./Styles/AddAsset.css";
import firebase from "firebase";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../AuthContext";

function AddAsset() {
  // params from the URL
  const { job_id, related_job } = useParams();

  // currentUser
  const {currentUser} = useAuth();

  // state variables
  const [state, setState] = React.useState({
    index: 0,
    successOpen: false, 
    successMessage: '', 
    errorMessage: '', 
    errorOpen: false, 
    loading: false
  });

  // handle index change
  const limit = 5;
  const progress = (100 / limit) * state.index;

  function handleIndexIncrease() {
    // increase index
    if (state.index === limit) {
      return;
    } else {
      setState((state) => ({
        ...state,
        index: state.index + 1,
      }));
    }
  }

  function handleIndexDecrease() {
    // reduce index
    if (state.index === 0) {
      return;
    } else {
      setState((state) => ({
        ...state,
        index: state.index - 1,
      }));
    }
  }

  async function handleFunctionCall() {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    const cloudFunction = await firebase
      .functions()
      .httpsCallable("handleEmployeeNewAsset");

    await cloudFunction({job_id: job_id, related_job,  uid: currentUser.uid}).then((r) => {

      if (r.data.success) {
        setState((state) => ({
          ...state, 
          loading: false, 
          successMessage: r.data.message, 
          successOpen: true,
          errorOpen: false, 
          errorMessage: 'false',
        }))

      } else { 
        setState((state) => ({
          ...state, 
          loading: false, 
          errorOpen: true, 
          errorMessage: r.data.message,
        }))
      }
    

      setState((state) => ({
        ...state,
        loading: false,
      }));
    });
  }


  const [vertical, setVertical] = React.useState("bottom");
  const [horizontal, setHorizontal] = React.useState("right");
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      errorOpen: false,
      successOpen: false,
      successMessage: "",
      errorMessage: "",
    }));
  };



  return (
    <>
          <Snackbar
        open={state.successOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="success">
          {state.successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleSnackClose} severity="info">
          {state.errorMessage}
        </Alert>
      </Snackbar>


      <Header />
      <div className="AddAsset">
        {state.loading && (
          <div className="loading">
            <CircularProgress style={{ color: "rgb(18, 100, 100)" }} />
          </div>
        )}
        <div className="progress">
          <LinearProgress
            variant="determinate"
            value={progress}
            color="inherit"
          />
        </div>

        <div className="slide">
          {state.index === 0 && <BasicDetails />}
          {state.index === 1 && <Installation />}
          {state.index === 2 && <WarrantyDetails />}
          {state.index === 3 && <ProductDetails />}
          {state.index === 4 && <Photos />}
          {state.index === 5 && <Review />}
        </div>

        <div className="nav-buttons">
          <Button
            style={{ color: "rgb(18, 100, 100)" }}
            disabled={state.index === 0}
            onClick={(e) => {
              handleIndexDecrease();
            }}
          >
            <ArrowBackIos /> Previous
          </Button>

          <Button
            style={{ color: "rgb(18, 100, 100)" }}
            onClick={(e) => {
              if (state.index === limit) {
                handleFunctionCall();
              } else {
                handleIndexIncrease();
              }
            }}
          >
            {state.index === limit ? "Submit" : "Next"} <ArrowForwardIos />
          </Button>
        </div>
      </div>
    </>
  );
}

export default AddAsset;
